import Cookies from 'js-cookie';
import {v4 as uuidv4} from 'uuid';

import logo from './logo.svg';
import './App.css';

import Chat from './Chat';
import { useEffect, useState } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL

function App() {

  // This code block makes sure that execute initialization in front of others. 
  const [isEffectFinished, setIsEffectFinished] = useState(false);

  useEffect(() => {
    const initialize = async () => {

      await processUuid();

      setIsEffectFinished(true);

    }

    initialize();
  }, []);

  if ( isEffectFinished === false) {
    return null;
  }

  // end

  return (
    <Chat />
  ) 
}

async function processUuid() {

  if ( Cookies.get('Aidehub : UUID') === undefined ) {

    const uuid = uuidv4();
    // console.info('uuid:', uuid);

    Cookies.set('Aidehub : UUID', uuid, { secure: true, expires: 365 });
    Cookies.set('Aidehub : UUID cookie created time', new Date().toISOString(), { secure: true, expires: 365 });

    // console.info('Does UUID Exist:', doesUUIDExist(uuid));
    if ( await doesUUIDExist(uuid) === false ) {
      await createUUID(uuid);
    }

  }

  else {
    console.info('Cookie UUID for aidehub.com:', Cookies.get('Aidehub : UUID'));

    const createdTime = new Date(Cookies.get('Aidehub : UUID cookie created time'))
    console.info('Cookie UUID created time:', createdTime);
    const timeDiff = new Date() - createdTime;

    console.info('Time diff:', timeDiff, msToDays(timeDiff));

    if ( 365 - msToDays(timeDiff) < 31 ) {
      const uuid = Cookies.get('Aidehub : UUID');

      Cookies.set('Aidehub : UUID', uuid, { secure: true, expires: 365 });
      Cookies.set('Aidehub : UUID cookie created time', new Date().toISOString(), { secure: true, expires: 365 });  
    
      await updateUUIDTime(uuid);
    }
  }

  return;
}

function msToDays(ms) {

  const seconds =      ms / 1000;
  const minutes = seconds / 60;
  const hours   = minutes / 60;
  const days    =   hours / 24;
  
  return days;

}

async function doesUUIDExist(uuid) {

  let response = undefined;

  try {
    // const 
    response = await axios.get(`${API_URL}/guests/uuid/${uuid}`);
  }
  catch (error) {
    console.error('Error on checking UUID:', error);
  }

  const result = ( response.data.length !== 0 );

  return result;
}

async function createUUID(uuid) {

  let response = undefined;

  try {
    response = await axios.post(`${API_URL}/guests`, { uuid: uuid});
  }
  catch (error) {
    console.error('Error on creating UUID:', error);
  }

  const result = ( response.status === 201 );

  return result;
}

async function updateUUIDTime(uuid) {

  let response = undefined;

  try {
    response = await axios.put(`${API_URL}/guests/update_uuid_time/${uuid}`);
  }
  catch (error) {
    console.error('Error on updating UUID time:', error);
  }

  const result = ( response.status === 200 );
  // console.info('Response status, result:', response.status, result);

  return result;
}

export default App;
