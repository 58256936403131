import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';

const API_URL = process.env.REACT_APP_API_URL
const API_KEY = process.env.REACT_APP_API_KEY

const UUID = Cookies.get('Aidehub : UUID');
console.info("UUID:", UUID);

if (UUID === undefined) {
  window.alert(
    "Error: The user code is undefined. "
      + "Please check the network connection. "
      + "Then press button OK to reload the page to try again."
  );
  window.location.reload();
}

function Chat() {
  const [messages, setMessages] = useState([]);
  const [localMessages, setLocalMessages] = useState([]);
  const messagesEndRef = useRef(null);

  // Fetch messages from the server
  useEffect(() => {
    async function fetchMessages() {
      const sender = UUID;

      try {
        const response = await axios.get (
          `${API_URL}/conversations/get_conversation_history_by_sender/${sender}`,
           { headers: { 'Authorization': API_KEY} }
        );

        setMessages(response.data);
        setLocalMessages(response.data);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    }

    fetchMessages();
  }, []);

  useEffect(() => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  }, [localMessages]);

  async function handleSubmit(e) {
    e.preventDefault();
    const text     = e.target.text.value;
    const sender   = UUID;
    const receiver = 'Chat Aide';

    try {
      const response = await axios.post(
        `${API_URL}/conversations/receive_new_message`,
          { message: text, sender: sender, receiver: receiver },
          { headers: { 'Authorization': API_KEY } }
      );

      setMessages([...messages, response.data]);
      setLocalMessages([...localMessages, response.data]);
    } catch (error) {
      console.error('Error posting message:', error);
    }

    e.target.text.value = '';
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: '#f2f2f2' }}>
      <h1 style={{color: '#1E90FF', fontSize: '3rem'}}>Aidehub (Alpha Test)</h1>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', height: '80vh', width: '50vw', backgroundColor: 'white', borderRadius: '10px', boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)', overflowY: 'auto' }}>
        {localMessages.map((msg, i) => {
          if (msg.sender === UUID) {
            return <p key={i} style={{ margin: '10px', backgroundColor: '#1e90ef', color: 'white', borderRadius: '10px', padding: '10px', alignSelf: 'flex-end' }}>{msg.message}</p>
          } else {
            return <p key={i} style={{ margin: '10px', backgroundColor: '#f2f2f2', color: 'black', borderRadius: '10px', padding: '10px', alignSelf: 'flex-start' }}>{msg.message}</p>
          }
        })}
        <div ref={messagesEndRef} />
      </div>
      <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
        <input name="text" style={{ padding: '10px', borderRadius: '5px', border: 'none', marginRight: '10px' }} />
        <button style={{ padding: '10px', borderRadius: '5px', border: 'none', backgroundColor: '#1E90FF', color: 'white' }}>Send</button>
      </form>
      <p style={{marginTop: '20px', fontSize: '1rem', color: '#808080'}}>© 2023 UniversE. All rights reserved.</p>
    </div>
  );
  
}


export default Chat;